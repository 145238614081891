define("discourse/plugins/discourse-events/discourse/templates/components/custom-wizard-field-event", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{event-form
    event=event
    eventTimezones=eventTimezones
    updateEvent=(action 'updateEvent')}}
  
  */
  {
    "id": "bwHfJYNr",
    "block": "[[[1,[28,[35,0],null,[[\"event\",\"eventTimezones\",\"updateEvent\"],[[30,0,[\"event\"]],[30,0,[\"eventTimezones\"]],[28,[37,1],[[30,0],\"updateEvent\"],null]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `event` property path was used in the `discourse/plugins/discourse-events/discourse/templates/components/custom-wizard-field-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.event}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `eventTimezones` property path was used in the `discourse/plugins/discourse-events/discourse/templates/components/custom-wizard-field-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.eventTimezones}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"event-form\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/components/custom-wizard-field-event.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});