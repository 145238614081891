define("discourse/plugins/discourse-events/discourse/templates/components/date-picker", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <input type="text" value={{value}} class="date-picker">
  
  */
  {
    "id": "DLti75kG",
    "block": "[[[44,[[28,[37,1],null,[[\"value\"],[[28,[32,0],[\"value\"],null]]]]],[[[10,\"input\"],[15,2,[52,[30,1,[\"value\"]],[28,[30,1,[\"value\"]],null,null],[28,[32,1],[[30,0],\"value\",\"[\\\"The `value` property path was used in the `discourse/plugins/discourse-events/discourse/templates/components/date-picker.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.value}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[14,0,\"date-picker\"],[14,4,\"text\"],[12],[13]],[1]]],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"let\",\"hash\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/components/date-picker.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});