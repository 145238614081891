define("discourse/plugins/discourse-events/discourse/routes/admin-events-connection", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-events/discourse/models/connection", "discourse/plugins/discourse-events/discourse/models/connection-filter", "discourse/plugins/discourse-events/discourse/models/source", "@ember/array", "discourse/plugins/discourse-events/discourse/lib/events"], function (_exports, _discourse, _connection, _connectionFilter, _source, _array, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _connection.default.all();
    },
    setupController(controller, model) {
      controller.setProperties({
        connections: (0, _array.A)(model.connections.map(c => {
          if (c.filters) {
            c.filters = (0, _array.A)(c.filters.map(f => {
              return _connectionFilter.default.create(f);
            }));
          }
          return _connection.default.create(c);
        })),
        sources: (0, _array.A)(model.sources.map(s => _source.default.create(s))),
        clients: (0, _events.contentsMap)(model.clients)
      });
    }
  });
});