define("discourse/plugins/discourse-events/discourse/controllers/admin-events-log", ["exports", "@ember/controller", "@ember/object/computed", "discourse/plugins/discourse-events/discourse/mixins/message"], function (_exports, _controller, _computed, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, {
    hasLogs: (0, _computed.notEmpty)("logs"),
    order: null,
    asc: null,
    view: "log"
  });
});