define("discourse/plugins/discourse-events/discourse/controllers/admin-events-provider", ["exports", "@ember/controller", "discourse/plugins/discourse-events/discourse/models/provider", "@ember/object/computed", "discourse/plugins/discourse-events/discourse/mixins/message", "I18n"], function (_exports, _controller, _provider, _computed, _message, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, {
    hasProviders: (0, _computed.notEmpty)("providers"),
    view: "provider",
    actions: {
      addProvider() {
        this.get("providers").pushObject(_provider.default.create({
          id: "new"
        }));
      },
      removeProvider(provider) {
        if (provider.id === "new") {
          this.get("providers").removeObject(provider);
        } else {
          bootbox.confirm(_I18n.default.t("admin.events.provider.remove.confirm", {
            provider_name: provider.name
          }), _I18n.default.t("cancel"), _I18n.default.t("admin.events.provider.remove.label"), result => {
            if (result) {
              _provider.default.destroy(provider).then(() => {
                this.get("providers").removeObject(provider);
              });
            }
          });
        }
      }
    }
  });
});