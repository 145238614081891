define("discourse/plugins/discourse-events/discourse/discourse-events-admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin",
    map() {
      this.route("events", {
        path: "/events"
      }, function () {
        this.route("provider", {
          path: "/provider"
        });
        this.route("source", {
          path: "/source"
        });
        this.route("connection", {
          path: "/connection"
        });
        this.route("event", {
          path: "/event"
        });
        this.route("log", {
          path: "/log"
        });
      });
    }
  };
});