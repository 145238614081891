define("discourse/plugins/discourse-events/discourse/templates/preferences/webcal-keys", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="control-label">{{i18n "webcal_preferences.heading"}}</label>
  {{event-webcal-keys}}
  
  */
  {
    "id": "bEizJ2Fm",
    "block": "[[[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"webcal_preferences.heading\"],null]],[13],[1,\"\\n\"],[41,[28,[32,0],[\"event-webcal-keys\"],null],[[[8,[39,2],null,null,null]],[]],[[[44,[[28,[37,4],null,[[\"event-webcal-keys\"],[[28,[32,1],[\"event-webcal-keys\"],null]]]]],[[[1,[52,[30,1,[\"event-webcal-keys\"]],[28,[30,1,[\"event-webcal-keys\"]],null,null],[28,[32,2],[[30,0],\"event-webcal-keys\",\"[\\\"The `event-webcal-keys` property path was used in the `discourse/plugins/discourse-events/discourse/templates/preferences/webcal-keys.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.event-webcal-keys}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"i18n\",\"if\",\"event-webcal-keys\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/preferences/webcal-keys.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});