define("discourse/plugins/discourse-events/discourse/components/events-calendar-subscription-row", ["exports", "select-kit/components/dropdown-select-box/dropdown-select-box-row", "discourse/lib/copy-text", "@ember/runloop"], function (_exports, _dropdownSelectBoxRow, _copyText, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBoxRow.default.extend({
    layoutName: "discourse/templates/components/events-calendar-subscription-row",
    classNames: "events-calendar-subscription-row",
    click() {
      const $copyRange = $('<p id="copy-range"></p>');
      $copyRange.html(this.item.id);
      $(document.body).append($copyRange);
      if ((0, _copyText.default)(this.item.id, $copyRange[0])) {
        this.set("copiedUrl", true);
        (0, _runloop.later)(() => this.set("copiedUrl", false), 2000);
      }
      $copyRange.remove();
    }
  });
});