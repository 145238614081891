define("discourse/plugins/discourse-events/discourse/components/event-form", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/runloop", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _component, _decorators, _runloop, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("startDate", "startTime", "endDate", "endTime", "endEnabled", "allDay", "timezone", "rsvpEnabled", "goingMax", "usersGoing"), _dec2 = (0, _decorators.default)(), _dec3 = (0, _decorators.default)("endEnabled"), (_obj = {
    classNames: "event-form",
    endEnabled: false,
    allDay: false,
    showTimezone: false,
    didInsertElement() {
      const props = (0, _dateUtilities.setupEventForm)(this.event, {
        siteSettings: this.siteSettings
      });
      this.setProperties(props);
      this.setupTimePicker("start");
      this.setupTimePicker("end");
      if (this.siteSettings.events_add_default_end_time && !this.event && !this.endDate && !this.endTime) {
        this.send("toggleEndEnabled", true);
      }
    },
    eventValid(event) {
      return !event || !event.end || moment(event.end).isSameOrAfter(event.start);
    },
    eventUpdated() {
      let event = (0, _dateUtilities.compileEvent)({
        startDate: this.startDate,
        startTime: this.startTime,
        endDate: this.endDate,
        endTime: this.endTime,
        endEnabled: this.endEnabled,
        allDay: this.allDay,
        timezone: this.timezone,
        rsvpEnabled: this.rsvpEnabled,
        goingMax: this.goingMax,
        usersGoing: this.usersGoing
      });
      this.updateEvent(event, this.eventValid(event));
    },
    setupTimePicker(type) {
      const time = this.get(`${type}Time`);
      (0, _runloop.later)(this, () => {
        (0, _runloop.scheduleOnce)("afterRender", this, () => {
          const $timePicker = $(`#${type}-time-picker`);
          $timePicker.timepicker({
            timeFormat: this.siteSettings.events_event_timepicker_format
          });
          $timePicker.timepicker("setTime", time);
          $timePicker.change(() => {
            this.set(`${type}Time`, $timePicker.timepicker("getTime"));
          });
        });
      });
    },
    timezones() {
      const eventTimezones = this.get("eventTimezones") || this.site.event_timezones;
      return eventTimezones.map(tz => {
        return {
          value: tz.value,
          name: (0, _dateUtilities.timezoneLabel)(tz.value, {
            siteSettings: this.siteSettings
          })
        };
      });
    },
    endClass(endEnabled) {
      return endEnabled ? "" : "disabled";
    },
    actions: {
      toggleEndEnabled(value) {
        this.set("endEnabled", value);
        if (value) {
          if (!this.endDate) {
            this.set("endDate", this.startDate);
          }
          if (!this.allDay) {
            if (!this.endTime) {
              let start = moment(this.startDate + " " + this.startTime);
              this.set("endTime", moment(start).add(1, "hours").format(_dateUtilities.formTimeFormat));
            }
            this.setupTimePicker("end");
          }
        } else {
          this.setProperties({
            endDate: undefined,
            endTime: undefined
          });
        }
      },
      toggleAllDay(value) {
        this.set("allDay", value);
        if (!value) {
          const start = (0, _dateUtilities.nextInterval)();
          this.set("startTime", start.format(_dateUtilities.formTimeFormat));
          this.setupTimePicker("start");
          if (this.endEnabled) {
            const end = moment(start).add(1, "hours");
            this.set("endTime", end.format(_dateUtilities.formTimeFormat));
            this.setupTimePicker("end");
          }
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "eventUpdated", [_dec], Object.getOwnPropertyDescriptor(_obj, "eventUpdated"), _obj), _applyDecoratedDescriptor(_obj, "timezones", [_dec2], Object.getOwnPropertyDescriptor(_obj, "timezones"), _obj), _applyDecoratedDescriptor(_obj, "endClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "endClass"), _obj)), _obj)));
});