define("discourse/plugins/discourse-events/discourse/components/add-to-calendar", ["exports", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse-common/utils/decorators", "@ember/runloop", "@ember/component"], function (_exports, _dateUtilities, _decorators, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("topic.event"), (_obj = {
    expanded: false,
    classNames: "add-to-calendar",
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    outsideClick(e) {
      if (!this.isDestroying && !$(e.target).closest(".add-to-calendar").length) {
        this.set("expanded", false);
      }
    },
    calendarUris() {
      const topic = this.get("topic");
      let params = {
        event: topic.event,
        title: topic.title,
        url: window.location.hostname + topic.get("url")
      };
      if (topic.location && topic.location.geo_location) {
        params["location"] = topic.location.geo_location.address;
      }
      return [{
        uri: (0, _dateUtilities.googleUri)(params),
        label: "google"
      }, {
        uri: (0, _dateUtilities.icsUri)(params),
        label: "ics"
      }];
    },
    actions: {
      expand() {
        this.toggleProperty("expanded");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "calendarUris", [_dec], Object.getOwnPropertyDescriptor(_obj, "calendarUris"), _obj)), _obj)));
});