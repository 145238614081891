define("discourse/plugins/discourse-events/discourse/components/events-calendar-body", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "@ember/component", "I18n"], function (_exports, _decorators, _dateUtilities, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("responsive"), _dec3 = (0, _decorators.observes)("currentMonth"), (_obj = {
    classNames: "events-calendar-body",
    expandedDate: 0.0,
    setup() {
      this._super();
      moment.locale(_I18n.default.locale);
    },
    weekdays(responsive) {
      let data = moment.localeData();
      let weekdays = $.extend([], responsive ? data.weekdaysMin() : data.weekdays());
      let firstDay = (0, _dateUtilities.firstDayOfWeek)();
      let beforeFirst = weekdays.splice(0, firstDay);
      weekdays.push(...beforeFirst);
      return weekdays;
    },
    resetExpandedDate() {
      this.set("expandedDate", null);
    },
    actions: {
      setExpandedDate(date) {
        event?.preventDefault();
        const month = this.get("currentMonth");
        this.set("expandedDate", month + "." + date);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "weekdays", [_dec2], Object.getOwnPropertyDescriptor(_obj, "weekdays"), _obj), _applyDecoratedDescriptor(_obj, "resetExpandedDate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "resetExpandedDate"), _obj)), _obj)));
});