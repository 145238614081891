define("discourse/plugins/discourse-events/discourse/routes/admin-events-provider", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-events/discourse/models/provider", "@ember/array"], function (_exports, _discourse, _provider, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _provider.default.all();
    },
    setupController(controller, model) {
      controller.setProperties({
        providers: (0, _array.A)(model.providers.map(p => _provider.default.create(p)))
      });
    }
  });
});