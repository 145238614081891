define("discourse/plugins/discourse-events/discourse/controllers/add-event", ["exports", "discourse/mixins/modal-functionality", "@ember/controller", "I18n", "@ember/object"], function (_exports, _modalFunctionality, _controller, _I18n, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    title: "add_event.modal_title",
    clear() {
      event?.preventDefault();
      this.set("bufferedEvent", null);
    },
    actions: {
      saveEvent() {
        if (this.valid) {
          this.get("model.update")(this.bufferedEvent);
          this.send("closeModal");
        } else {
          this.flash(_I18n.default.t("add_event.error"), "error");
        }
      },
      updateEvent(event, valid) {
        this.set("bufferedEvent", event);
        this.set("valid", valid);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "clear", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clear"), _obj)), _obj));
});