define("discourse/plugins/discourse-events/discourse/components/events-provider-row", ["exports", "@ember/component", "discourse/plugins/discourse-events/discourse/models/provider", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/lib/events", "@ember/object/computed"], function (_exports, _component, _provider, _decorators, _events, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const TOKEN_TYPES = ["eventbrite", "humanitix", "eventzilla"];
  const NO_AUTH_TYPES = ["icalendar"];
  const OAUTH2_TYPES = ["meetup"];
  const PROVIDER_TYPES = [...NO_AUTH_TYPES, ...TOKEN_TYPES, ...OAUTH2_TYPES];
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("provider.name", "provider.url", "provider.provider_type", "provider.url"), _dec2 = (0, _decorators.default)("providerChanged"), _dec3 = (0, _decorators.default)("providerChanged"), _dec4 = (0, _decorators.default)("canAuthenicate", "providerChanged", "provider.authenticated"), _dec5 = (0, _decorators.default)("authenticateDisabled"), _dec6 = (0, _decorators.default)("provider.provider_type"), _dec7 = (0, _decorators.default)("provider.provider_type"), _dec8 = (0, _decorators.default)("provider.provider_type"), _dec9 = (0, _decorators.default)("provider.provider_type"), (_obj = {
    tagName: "tr",
    classNames: ["events-provider-row"],
    attributeBindings: ["provider.id:data-provider-id"],
    hideCredentials: true,
    hasSecretCredentials: (0, _computed.or)("showToken", "showClientCredentials"),
    didReceiveAttrs() {
      this.set("currentProvider", JSON.parse(JSON.stringify(this.provider)));
    },
    providerChanged(name, url, type) {
      const cp = this.currentProvider;
      return cp.name !== name || cp.url !== url || cp.provider_type !== type;
    },
    saveDisabled(providerChanged) {
      return !providerChanged;
    },
    saveClass(providerChanged) {
      return providerChanged ? "save-provider btn-primary" : "save-provider";
    },
    providerTypes() {
      return (0, _events.contentsMap)(PROVIDER_TYPES);
    },
    authenticateDisabled(canAuthenicate, providerChanged, providerAuthenticated) {
      return !canAuthenicate || providerChanged || providerAuthenticated;
    },
    authenticateClass(authenticateDisabled) {
      return authenticateDisabled ? "" : "btn-primary";
    },
    canAuthenicate(providerType) {
      return providerType && OAUTH2_TYPES.includes(providerType);
    },
    showToken(providerType) {
      return providerType && TOKEN_TYPES.includes(providerType);
    },
    showNoAuth(providerType) {
      return !providerType || NO_AUTH_TYPES.includes(providerType);
    },
    showClientCredentials(providerType) {
      return providerType && OAUTH2_TYPES.includes(providerType);
    },
    actions: {
      toggleHideCredentials() {
        this.toggleProperty("hideCredentials");
      },
      saveProvider() {
        const provider = JSON.parse(JSON.stringify(this.provider));
        if (!provider.name) {
          return;
        }
        this.set("saving", true);
        _provider.default.update(provider).then(result => {
          if (result) {
            this.setProperties({
              currentProvider: result.provider,
              provider: _provider.default.create(result.provider)
            });
          } else if (this.currentSource.id !== "new") {
            this.set("provider", JSON.parse(JSON.stringify(this.currentProvider)));
          }
        }).finally(() => {
          this.set("saving", false);
        });
      },
      authenticateProvider() {
        window.location.href = `/admin/events/provider/${this.provider.id}/authorize`;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "providerChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "providerChanged"), _obj), _applyDecoratedDescriptor(_obj, "saveDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj), _applyDecoratedDescriptor(_obj, "saveClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "saveClass"), _obj), _applyDecoratedDescriptor(_obj, "providerTypes", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "providerTypes"), _obj), _applyDecoratedDescriptor(_obj, "authenticateDisabled", [_dec4], Object.getOwnPropertyDescriptor(_obj, "authenticateDisabled"), _obj), _applyDecoratedDescriptor(_obj, "authenticateClass", [_dec5], Object.getOwnPropertyDescriptor(_obj, "authenticateClass"), _obj), _applyDecoratedDescriptor(_obj, "canAuthenicate", [_dec6], Object.getOwnPropertyDescriptor(_obj, "canAuthenicate"), _obj), _applyDecoratedDescriptor(_obj, "showToken", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showToken"), _obj), _applyDecoratedDescriptor(_obj, "showNoAuth", [_dec8], Object.getOwnPropertyDescriptor(_obj, "showNoAuth"), _obj), _applyDecoratedDescriptor(_obj, "showClientCredentials", [_dec9], Object.getOwnPropertyDescriptor(_obj, "showClientCredentials"), _obj)), _obj)));
});