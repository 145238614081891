define("discourse/plugins/discourse-events/discourse/controllers/event-rsvp", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/get-owner", "discourse/lib/ajax", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error", "@ember/controller", "@ember/object"], function (_exports, _decorators, _getOwner, _ajax, _modalFunctionality, _ajaxError, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.observes)("type", "model.topic"), _dec2 = (0, _decorators.default)("type"), _dec3 = (0, _decorators.default)("userList", "filter"), (_obj = {
    filter: null,
    userList: [],
    type: "going",
    setUserList() {
      this.set("loadingList", true);
      const type = this.get("type");
      const topic = this.get("model.topic");
      let usernames = topic.get(`event.${type}`);
      if (!usernames || !usernames.length) {
        return;
      }
      (0, _ajax.ajax)("/discourse-events/rsvp/users", {
        data: {
          usernames
        }
      }).then(response => {
        let userList = response.users || [];
        this.setProperties({
          userList,
          loadingList: false
        });
      }).catch(e => {
        this.flash((0, _ajaxError.extractError)(e), "alert-error");
      }).finally(() => {
        this.setProperties({
          loadingList: false
        });
      });
    },
    goingNavClass(type) {
      return type === "going" ? "active" : "";
    },
    filteredList(userList, filter) {
      if (filter) {
        userList = userList.filter(u => u.username.indexOf(filter) > -1);
      }
      const currentUser = this.get("currentUser");
      if (currentUser) {
        userList.sort(a => {
          if (a.username === currentUser.username) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      return userList;
    },
    setType(type) {
      event?.preventDefault();
      this.set("type", type);
    },
    actions: {
      composePrivateMessage(user) {
        const controller = (0, _getOwner.getOwner)(this).lookup("controller:application");
        this.send("closeModal");
        controller.send("composePrivateMessage", user);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setUserList", [_dec], Object.getOwnPropertyDescriptor(_obj, "setUserList"), _obj), _applyDecoratedDescriptor(_obj, "goingNavClass", [_dec2], Object.getOwnPropertyDescriptor(_obj, "goingNavClass"), _obj), _applyDecoratedDescriptor(_obj, "filteredList", [_dec3], Object.getOwnPropertyDescriptor(_obj, "filteredList"), _obj), _applyDecoratedDescriptor(_obj, "setType", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setType"), _obj)), _obj)));
});