define("discourse/plugins/discourse-events/discourse/initializers/discourse-events", ["exports", "discourse/models/category", "discourse-common/utils/decorators", "discourse/lib/url", "discourse/lib/plugin-api", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse/models/composer", "@ember/runloop", "@ember/object", "I18n"], function (_exports, _category, _decorators, _url, _pluginApi, _dateUtilities, _composer, _runloop, _object, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "events-edits",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const currentUser = container.lookup("current-user:main");
      (0, _pluginApi.withPluginApi)("1.4.0", api => {
        var _dec, _dec2, _obj, _dec3, _dec4, _obj2, _dec5, _dec6, _dec7, _obj3, _dec8, _obj4, _dec9, _dec10, _dec11, _obj5, _dec12, _obj6, _dec13, _obj7, _dec15, _obj9, _dec16, _dec17, _obj10;
        api.serializeToDraft("event");
        api.serializeOnCreate("event");
        api.serializeToTopic("event", "topic.event");
        api.addDiscoveryQueryParam("end", {
          replace: true,
          refreshModel: true
        });
        api.addDiscoveryQueryParam("start", {
          replace: true,
          refreshModel: true
        });
        api.modifyClass("model:composer", (_dec = (0, _decorators.default)("subtype", "category.events_enabled", "topicFirstPost", "topic.event", "canCreateEvent"), _dec2 = (0, _decorators.default)("category.events_min_trust_to_create"), (_obj = {
          pluginId: "events",
          showEventControls(subtype, categoryEnabled, topicFirstPost, event, canCreateEvent) {
            return topicFirstPost && (subtype === "event" || categoryEnabled || event) && canCreateEvent;
          },
          canCreateEvent(minTrust) {
            return currentUser.staff || currentUser.trust_level >= minTrust;
          }
        }, (_applyDecoratedDescriptor(_obj, "showEventControls", [_dec], Object.getOwnPropertyDescriptor(_obj, "showEventControls"), _obj), _applyDecoratedDescriptor(_obj, "canCreateEvent", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canCreateEvent"), _obj)), _obj)));
        api.modifyClass("component:composer-body", (_dec3 = (0, _decorators.observes)("composer.event"), _dec4 = (0, _decorators.observes)("composer.showEventControls", "composer.composeState"), (_obj2 = {
          pluginId: "events",
          resizeWhenEventAdded() {
            this.composerResized();
          },
          applyEventInlineClass() {
            (0, _runloop.scheduleOnce)("afterRender", this, () => {
              const showEventControls = this.get("composer.showEventControls");
              const $container = $(".composer-fields .title-and-category");
              $container.toggleClass("show-event-controls", Boolean(showEventControls));
              if (showEventControls) {
                const $anchor = this.site.mobileView ? $container.find(".title-input") : $container;
                $(".composer-controls-event").appendTo($anchor);
              }
              this.composerResized();
            });
          }
        }, (_applyDecoratedDescriptor(_obj2, "resizeWhenEventAdded", [_dec3], Object.getOwnPropertyDescriptor(_obj2, "resizeWhenEventAdded"), _obj2), _applyDecoratedDescriptor(_obj2, "applyEventInlineClass", [_dec4], Object.getOwnPropertyDescriptor(_obj2, "applyEventInlineClass"), _obj2)), _obj2)));
        api.modifyClass("model:topic", (_dec5 = (0, _decorators.default)("subtype", "category.events_enabled", "canCreateEvent"), _dec6 = (0, _decorators.default)("category.events_min_trust_to_create"), _dec7 = (0, _decorators.default)("last_read_post_number", "highest_post_number"), (_obj3 = {
          pluginId: "events",
          showEventControls(subtype, categoryEnabled, canCreateEvent) {
            return (subtype === "event" || categoryEnabled) && canCreateEvent;
          },
          canCreateEvent(minTrust) {
            return currentUser.staff || currentUser.trust_level >= minTrust;
          },
          topicListItemClasses(lastRead, highest) {
            let classes = "date-time title raw-link event-link raw-topic-link";
            if (lastRead === highest) {
              classes += " visited";
            }
            return classes;
          }
        }, (_applyDecoratedDescriptor(_obj3, "showEventControls", [_dec5], Object.getOwnPropertyDescriptor(_obj3, "showEventControls"), _obj3), _applyDecoratedDescriptor(_obj3, "canCreateEvent", [_dec6], Object.getOwnPropertyDescriptor(_obj3, "canCreateEvent"), _obj3), _applyDecoratedDescriptor(_obj3, "topicListItemClasses", [_dec7], Object.getOwnPropertyDescriptor(_obj3, "topicListItemClasses"), _obj3)), _obj3)));

        // necessary because topic-title plugin outlet only recieves model
        api.modifyClass("controller:topic", (_dec8 = (0, _decorators.observes)("editingTopic"), (_obj4 = {
          pluginId: "events",
          setEditingTopicOnModel() {
            this.set("model.editingTopic", this.get("editingTopic"));
          }
        }, (_applyDecoratedDescriptor(_obj4, "setEditingTopicOnModel", [_dec8], Object.getOwnPropertyDescriptor(_obj4, "setEditingTopicOnModel"), _obj4)), _obj4)));
        api.addNavigationBarItem({
          name: "calendar",
          displayName: "Calendar",
          customFilter: category => {
            return siteSettings.events_calendar_enabled || category && category.events_calendar_enabled;
          },
          customHref: category => {
            if (category) {
              return `${category.url}/l/calendar`;
            } else {
              return "/calendar";
            }
          }
        });
        api.addNavigationBarItem({
          name: "agenda",
          displayName: "Agenda",
          customFilter: category => {
            return siteSettings.events_agenda_enabled || category && category.events_agenda_enabled;
          },
          customHref: category => {
            if (category) {
              return `${category.url}/l/agenda`;
            } else {
              return "/agenda";
            }
          }
        });
        api.modifyClass("component:topic-list-item", (_dec9 = (0, _decorators.on)("didInsertElement"), _dec10 = (0, _decorators.on)("willDestroyElement"), _dec11 = (0, _decorators.on)("didRender"), (_obj5 = {
          pluginId: "events",
          setupEventLink() {
            (0, _runloop.scheduleOnce)("afterRender", this, () => {
              $(".event-link", this.element).on("click", (0, _runloop.bind)(this, this.handleEventLabelClick));
            });
          },
          teardownEventLink() {
            $(".event-link", this.element).off("click", (0, _runloop.bind)(this, this.handleEventLabelClick));
          },
          handleEventLabelClick(e) {
            e.preventDefault();
            const topic = this.get("topic");
            const href = $(e.target).attr("href");
            this.appEvents.trigger("header:update-topic", topic);
            _url.default.routeTo(href);
          },
          moveElements() {
            const topic = this.get("topic");
            (0, _runloop.scheduleOnce)("afterRender", () => {
              const $linkTopLine = $(".link-top-line", this.element);
              let rowBelowTitle = false;
              if (topic.event && topic.event.rsvp) {
                $(".topic-list-event-rsvp", this.element).insertAfter($linkTopLine);
                rowBelowTitle = true;
              }
              if (this.siteSettings.events_event_label_short_after_title) {
                $(".date-time-container", this.element).insertAfter($linkTopLine);
                rowBelowTitle = true;
              }
              if (rowBelowTitle) {
                $(".main-link", this.element).addClass("row-below-title");
              }
            });
          }
        }, (_applyDecoratedDescriptor(_obj5, "setupEventLink", [_dec9], Object.getOwnPropertyDescriptor(_obj5, "setupEventLink"), _obj5), _applyDecoratedDescriptor(_obj5, "teardownEventLink", [_dec10], Object.getOwnPropertyDescriptor(_obj5, "teardownEventLink"), _obj5), _applyDecoratedDescriptor(_obj5, "moveElements", [_dec11], Object.getOwnPropertyDescriptor(_obj5, "moveElements"), _obj5)), _obj5)));
        api.modifyClass("component:edit-category-settings", (_dec12 = (0, _decorators.default)("category"), (_obj6 = {
          pluginId: "events",
          availableViews(category) {
            let views = this._super(...arguments);
            if (category.get("custom_fields.events_agenda_enabled")) {
              views.push({
                name: _I18n.default.t("filters.agenda.title"),
                value: "agenda"
              });
            }
            if (category.get("custom_fields.events_calendar_enabled")) {
              views.push({
                name: _I18n.default.t("filters.calendar.title"),
                value: "calendar"
              });
            }
            return views;
          }
        }, (_applyDecoratedDescriptor(_obj6, "availableViews", [_dec12], Object.getOwnPropertyDescriptor(_obj6, "availableViews"), _obj6)), _obj6)));
        const calendarRoutes = [`calendar`, `calendarCategory`, `calendarCategoryNone`];
        calendarRoutes.forEach(route => {
          api.modifyClass(`route:discovery.${route}`, {
            pluginId: "events",
            beforeModel(transition) {
              const routeName = this.routeName;
              const queryParams = this.paramsFor(routeName);
              if (!queryParams.start || !queryParams.end) {
                const month = moment().month();
                const year = moment().year();
                const {
                  start,
                  end
                } = (0, _dateUtilities.calendarRange)(month, year);
                this.setProperties({
                  start,
                  end
                });
              }
              this._super(transition);
            },
            setupController(controller, model) {
              const start = this.get("start");
              const end = this.get("end");
              if (start || end) {
                let initialDateRange = {};
                if (start) {
                  initialDateRange["start"] = start;
                }
                if (end) {
                  initialDateRange["end"] = end;
                }
                this.controllerFor("discovery/topics").setProperties({
                  initialDateRange
                });
              }
              this._super(controller, model);
            },
            renderTemplate(controller, model) {
              // respect discourse-layouts settings
              const global = siteSettings.layouts_list_navigation_disabled_global;
              const catGlobal = model.category && model.category.get("layouts_list_navigation_disabled_global");
              if (!global && !catGlobal) {
                if (this.routeName.indexOf("Category") > -1) {
                  this.render("navigation/category", {
                    outlet: "navigation-bar"
                  });
                } else {
                  this.render("navigation/default", {
                    outlet: "navigation-bar"
                  });
                }
              }
              this.render("discovery/calendar", {
                outlet: "list-container",
                controller: "discovery/topics"
              });
            }
          });
        });
        const categoryRoutes = ["category", "categoryNone"];
        categoryRoutes.forEach(function (route) {
          api.modifyClass(`route:discovery.${route}`, {
            pluginId: "events",
            afterModel(model) {
              const filter = this.filter(model.category);
              if (filter === "calendar" || filter === "agenda") {
                return this.replaceWith(`/c/${_category.default.slugFor(model.category)}/l/${this.filter(model.category)}`);
              } else {
                return this._super(...arguments);
              }
            }
          });
        });
        api.modifyClass("controller:preferences/interface", (_dec13 = (0, _decorators.default)("makeThemeDefault"), (_obj7 = {
          pluginId: "events",
          saveAttrNames(makeDefault) {
            let attrs = this._super(makeDefault);
            attrs.push("custom_fields");
            return attrs;
          }
        }, (_applyDecoratedDescriptor(_obj7, "saveAttrNames", [_dec13], Object.getOwnPropertyDescriptor(_obj7, "saveAttrNames"), _obj7)), _obj7)));
        if (siteSettings.events_hamburger_menu_calendar_link) {
          api.decorateWidget("hamburger-menu:generalLinks", () => {
            return {
              route: "discovery.calendar",
              className: "calendar-link",
              label: "filters.calendar.title"
            };
          });
        }
        if (siteSettings.events_hamburger_menu_agenda_link) {
          api.decorateWidget("hamburger-menu:generalLinks", () => {
            return {
              route: "discovery.agenda",
              className: "agenda-link",
              label: "filters.agenda.title"
            };
          });
        }
        const user = api.getCurrentUser();
        if (user && user.admin) {
          var _dec14, _obj8;
          api.modifyClass("model:site-setting", (_dec14 = (0, _decorators.default)("valid_values"), (_obj8 = {
            pluginId: "events",
            allowsNone() {
              if (this.get("setting") === "events_timezone_default") {
                return "site_settings.events_timezone_default_placeholder";
              } else {
                this._super();
              }
            }
          }, (_applyDecoratedDescriptor(_obj8, "allowsNone", [_dec14], Object.getOwnPropertyDescriptor(_obj8, "allowsNone"), _obj8)), _obj8)));
        }
        api.modifyClass("controller:topic", (_dec15 = (0, _decorators.observes)("model.id"), (_obj9 = {
          pluginId: "events",
          subscribeDiscourseEvents() {
            this.unsubscribeDiscourseEvents();
            this.messageBus.subscribe(`/discourse-events/${this.get("model.id")}`, data => {
              if (data.current_user_id === currentUser.id) {
                return;
              }
              switch (data.type) {
                case "rsvp":
                  {
                    let prop = Object.keys(data).filter(p => p.indexOf("event") > -1);
                    if (prop && prop[0]) {
                      let key = prop[0].split("_").join(".");
                      this.set(`model.${key}`, data[prop[0]]);
                      this.notifyPropertyChange(`model.${prop}`);
                    }
                  }
              }
            });
          },
          unsubscribeDiscourseEvents() {
            this.messageBus.unsubscribe(`/discourse-events/${this.get("model.id")}`);
          }
        }, (_applyDecoratedDescriptor(_obj9, "subscribeDiscourseEvents", [_dec15], Object.getOwnPropertyDescriptor(_obj9, "subscribeDiscourseEvents"), _obj9)), _obj9)));
        api.modifyClass("controller:composer", (_dec16 = (0, _decorators.default)("model.action", "model.event", "model.category.events_required", "lastValidatedAt"), _dec17 = (0, _decorators.observes)("model.composeState"), (_obj10 = {
          pluginId: "events",
          eventValidation(action, event, eventsRequired, lastValidatedAt) {
            if (action === _composer.CREATE_TOPIC && eventsRequired && !event) {
              return _object.default.create({
                failed: true,
                reason: _I18n.default.t("composer.error.event_missing"),
                lastShownAt: lastValidatedAt
              });
            }
          },
          ensureEvent() {
            if (this.model && this.model.topic && this.model.topic.event && !this.model.event) {
              this.set("model.event", this.model.topic.event);
            }
          },
          // overriding cantSubmitPost on the model is more fragile
          save() {
            if (!this.get("eventValidation")) {
              this._super(...arguments);
            } else {
              this.set("lastValidatedAt", Date.now());
            }
          }
        }, (_applyDecoratedDescriptor(_obj10, "eventValidation", [_dec16], Object.getOwnPropertyDescriptor(_obj10, "eventValidation"), _obj10), _applyDecoratedDescriptor(_obj10, "ensureEvent", [_dec17], Object.getOwnPropertyDescriptor(_obj10, "ensureEvent"), _obj10)), _obj10)));
        api.includePostAttributes("connected_event", "connected_event");
        api.addPostClassesCallback(attrs => {
          if (attrs.post_number === 1 && attrs.connected_event) {
            return ["for-event"];
          }
        });
        api.decorateWidget("post-menu:before-extra-controls", helper => {
          const post = helper.getModel();
          if (post.connected_event && post.connected_event.can_manage) {
            return helper.attach("link", {
              attributes: {
                target: "_blank"
              },
              href: post.connected_event.admin_url,
              className: "manage-event",
              icon: "external-link-alt",
              label: "post.event.manage.label",
              title: "post.event.manage.title"
            });
          }
        });
      });
    }
  };
});