define("discourse/plugins/discourse-events/discourse/controllers/admin-events-source", ["exports", "@ember/controller", "discourse/plugins/discourse-events/discourse/models/source", "discourse/plugins/discourse-events/discourse/models/source-options", "@ember/object/computed", "discourse/plugins/discourse-events/discourse/mixins/message", "I18n"], function (_exports, _controller, _source, _sourceOptions, _computed, _message, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, {
    hasSources: (0, _computed.notEmpty)("sources"),
    view: "source",
    actions: {
      addSource() {
        this.get("sources").pushObject(_source.default.create({
          id: "new",
          source_options: _sourceOptions.default.create(),
          from_time: moment().subtract(1, "months").add(30, "minutes").startOf("hour"),
          to_time: moment().add(5, "months").add(30, "minutes").startOf("hour")
        }));
      },
      removeSource(source) {
        if (source.id === "new") {
          this.get("sources").removeObject(source);
        } else {
          bootbox.confirm(_I18n.default.t("admin.events.source.remove.confirm", {
            source_name: source.name
          }), _I18n.default.t("cancel"), _I18n.default.t("admin.events.source.remove.label"), result => {
            if (result) {
              _source.default.destroy(source).then(() => {
                this.get("sources").removeObject(source);
              });
            }
          });
        }
      }
    }
  });
});