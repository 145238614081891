define("discourse/plugins/discourse-events/discourse/components/events-calendar-event", ["exports", "discourse/lib/url", "@ember/component", "@ember/object"], function (_exports, _url, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    tagName: "li",
    selectEvent(url) {
      event?.preventDefault();
      const responsive = this.get("responsive");
      if (responsive) {
        _url.default.routeTo(url);
      } else {
        this.toggleProperty("showEventCard");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "selectEvent", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectEvent"), _obj)), _obj));
});