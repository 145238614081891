define("discourse/plugins/discourse-events/discourse/components/events-calendar-card", ["exports", "discourse/lib/url", "discourse/lib/text", "discourse-common/utils/decorators", "@ember/runloop", "@ember/component", "@ember/object"], function (_exports, _url, _text, _decorators, _runloop, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("init"), (_obj = {
    classNames: "events-calendar-card",
    attributeBindings: ["topic.id:data-topic-id"],
    setup() {
      const excerpt = this.get("topic.excerpt");
      const title = this.get("topic.title");
      (0, _text.cook)(excerpt).then(cooked => this.set("cookedExcerpt", cooked));
      (0, _text.cook)(title).then(cooked => this.set("cookedTitle", cooked));
    },
    didInsertElement() {
      this.set("clickHandler", (0, _runloop.bind)(this, this.documentClick));
      (0, _runloop.next)(() => $(document).on("mousedown", this.get("clickHandler")));
      (0, _runloop.scheduleOnce)("afterRender", () => {
        const offsetLeft = this.element.closest(".day").offsetLeft;
        const offsetTop = this.element.closest(".day").offsetTop;
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
        let styles;
        if (offsetLeft > windowWidth / 2) {
          styles = {
            left: "-390px",
            right: "initial"
          };
        } else {
          styles = {
            right: "-390px",
            left: "initial"
          };
        }
        if (offsetTop > windowHeight / 2) {
          styles = Object.assign(styles, {
            bottom: "-15px",
            top: "initial"
          });
        } else {
          styles = Object.assign(styles, {
            top: "-15px",
            bottom: "initial"
          });
        }
        Object.keys(styles).forEach(key => {
          this.element.style[key] = styles[key];
        });
      });
    },
    willDestroyElement() {
      $(document).off("mousedown", this.get("clickHandler"));
    },
    documentClick(event) {
      if (!event.target.closest(`div.events-calendar-card[data-topic-id='${this.topic.id}']`)) {
        this.clickOutside();
      }
    },
    clickOutside() {
      this.close();
    },
    close() {
      event?.preventDefault();
      this.selectEvent();
    },
    goToTopic() {
      event?.preventDefault();
      const url = this.get("topic.url");
      _url.default.routeTo(url);
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "close", [_object.action], Object.getOwnPropertyDescriptor(_obj, "close"), _obj), _applyDecoratedDescriptor(_obj, "goToTopic", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToTopic"), _obj)), _obj)));
});