define("discourse/plugins/discourse-events/discourse/templates/modal/add-event", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#d-modal-body class="add-event-modal" id="event-modal" title=title}}
    {{event-form
      event=model.event
      updateEvent=(action 'updateEvent')}}
  {{/d-modal-body}}
  
  <div class="modal-footer">
    {{d-button action=(action "saveEvent") class="btn-primary" label="add_event.event_add"}}
    <a href class="clear" {{on "click" (fn this.clear)}}>{{i18n 'add_event.event_clear'}}</a>
  </div>
  
  */
  {
    "id": "BeKAPzjO",
    "block": "[[[6,[39,0],null,[[\"class\",\"id\",\"title\"],[\"add-event-modal\",\"event-modal\",[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"event\",\"updateEvent\"],[[30,0,[\"model\",\"event\"]],[28,[37,2],[[30,0],\"updateEvent\"],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"action\",\"class\",\"label\"],[[28,[37,2],[[30,0],\"saveEvent\"],null],\"btn-primary\",\"add_event.event_add\"]]]],[1,\"\\n  \"],[11,3],[24,6,\"\"],[24,0,\"clear\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"clear\"]]],null]],null],[12],[1,[28,[35,6],[\"add_event.event_clear\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `title` property path was used in the `discourse/plugins/discourse-events/discourse/templates/modal/add-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.title}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-events/discourse/templates/modal/add-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-modal-body\",\"event-form\",\"action\",\"d-button\",\"on\",\"fn\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/modal/add-event.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});